<template>
  <div class="relative card">
    <div class="absolute right-6 z-10 w-10 h-12 rounded-bl-lg rounded-br-lg flex items-center justify-center favorite">
      <button
        title="Loading Add to wishlist"
        type="button"
        class="ProductCard__favorite relative [ items-center flex justify-center h-5 w-5 rounded-full ]"
      ></button>
    </div>
    <div class="h-full overflow-hidden flex flex-col">
      <div class="thumb mx-auto loading" />
      <div class="content relative">
        <div class="[ absolute right-3 top-3 ]">
          <div class="loading h-5 w-5 rounded-full"></div>
        </div>
        <div class="loading mt-3 h-5 w-3/4"></div>
        <div class="loading h-5 mt-1 w-3/4"></div>
        <div class="loading h-5 w-1/4 mt-2"></div>
        <div class="w-1/3 h-5 loading my-2"></div>
        <div class="w-1/3 h-5 loading my-2"></div>
      </div>
    </div>
  </div>
</template>

<style lang="postcss" scoped>
.thumb {
  width: 100%;

  @screen lg {
    width: 277px;
    height: 400px;
  }
}

.content {
  @apply flex flex-col relative  justify-around;
  @screen lg {
    min-height: 195px;
  }
}
.ProductCard__favorite {
  @apply flex items-center;
  transition: transform 0.2s ease-in-out;
  &:hover {
    transform: scale(1.2);
  }

  svg.is-active path {
    @apply fill-current text-system-red;
  }
}
</style>
